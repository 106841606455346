/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function() {
                /**
                 *  Init WOW
                 *  @link https://wowjs.uk/
                 */
                var wow = new WOW({
                    boxClass: "animate", // animated element css class (default is wow)
                    offset: 100 // distance to the element when triggering the animation (default is 0)
                });
                wow.init();

                /**
                 *  Add shrink class to header on scroll
                 */
                var $body = $("body"),
                    $threshold = 65,
                    $window = $(window);

                $window.scroll(function() {
                    $scroll_position = $window.scrollTop();
                    if ($scroll_position > $threshold) {
                        $body.addClass("shrink");
                    } else {
                        $body.removeClass("shrink");
                    }
                });

                /**
                 *  Mobile Menu trigger
                 */
                $(".menu-toggle, .close-button").on(
                    "click touchstart",
                    function(e) {
                        e.preventDefault();
                        if ($body.hasClass("menu-open")) {
                            $body.removeClass("menu-open");
                        } else {
                            $body.addClass("menu-open");
                        }
                    }
                );

                /**
                 *  Mobile sub menu expansion
                 */

                  $('.menus.mobile .menu-item-has-children > a').on('click touchstart', function (e) {

                        e.preventDefault();

                        var panel = $(this).next('.sub-menu');

                        if (panel[0].style.maxHeight) {
                          panel[0].style.maxHeight = null;
                        } else {
                          panel[0].style.maxHeight = panel[0].scrollHeight + "px";
                        } 

                  });

                /**
                 *  Scroll to anchor links
                 */
                $("a[href^='#']").on("click touchstart", function(e) {
                    e.preventDefault();
                    href = $(this).attr("href");

                    if (href.length > 1) {
                        $("html, body").animate(
                            {
                                scrollTop: $(href).offset().top - 200
                            },
                            500
                        );
                    }
                });

                /**
                 *  Nav page selector functionality
                 */

                $navSelector = $(".nav-page-selector");
                $navSelectorContainer = $(".nav-page-selector-container");
                
                $navSelector.on("click", function(e) {
                    $navSelectorContainer.next().slideToggle('fast');
                    $(this).toggleClass('open');
                    $navSelectorContainer.parent().parent().parent().toggleClass('open');
                });

                $(window).scroll(function() {
                    $navSelectorContainer.next().slideUp('fast');
                    $navSelectorContainer.removeClass('open');
                    $navSelectorContainer.parent().parent().parent().removeClass('open');
                });

                /**
                 *  Floating labels on forms
                 */
                $(".gfield").each(function() {
                    $inputContainer = $(this).find(".ginput_container");

                    if ($inputContainer.hasClass("ginput_container_textarea")) {
                        $input = $(this).find("textarea");
                    } else {
                        $input = $(this).find("input");
                    }

                    $input.on("change keyup input focusin", function() {
                        $(this)
                            .parent()
                            .parent()
                            .addClass("has-input");
                    });

                    $input.on("focusout", function() {
                        if ($(this).val() !== "") {
                            $(this)
                                .parent()
                                .parent()
                                .addClass("has-input");
                        } else {
                            $(this)
                                .parent()
                                .parent()
                                .removeClass("has-input");
                        }
                    });
                });

                /**
                 * Gated functionality
                 */
                
                $("a[data-guide-id]").on("click", function(e) {
                    e.preventDefault();
                    $file_id = $(this).data('guide-id');
                    $field_id = $(this).data('field-id');
                    $product_id = $(this).data('product-id');
                    $file_name = $(this).data('filename');

                    $('#product-' + $product_id + ' .requested-file-id input').val($file_id);
                    $('#product-' + $product_id + ' .requested-file-name input').val($file_name);
                    $('#product-' + $product_id + ' .gated-download-form').fadeIn('fast');
                });

                $(".gated_download_form--close").on("click", function(e) {
                    e.preventDefault();
                    $('.gated-download-form').fadeOut('fast');
                });

                /** 
                 * Down indicator functionality
                 */

                 $('.down-indicator').on('click', function (e) {
                    $parentDataLayoutID = $(this).parent('section').data('layout');
                    $nextSection = $parentDataLayoutID + 1;
                    $("html, body").animate(
                        {
                            scrollTop: $('section[data-layout=' + $nextSection + ']').offset().top - 200
                        },
                        500
                    );
                    console.log( $parentDataLayoutID );
                 });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        products_list_block: {
            init: function() {
                $(".more-info-toggle").on("click touchstart", function(e) {
                    e.preventDefault();
                    $(this).toggleClass("open");
                    $(this)
                        .prev()
                        .slideToggle();
                });
            }
        },
        form_block: {
            init: function() {

                /**
                 *  Floating labels on forms
                 */
                $(".gfield").each(function() {
                    $inputContainer = $(this).find(".ginput_container");

                    if ($inputContainer.hasClass("ginput_container_textarea")) {
                        $input = $(this).find("textarea");
                    } else {
                        $input = $(this).find("input");
                    }

                    $input.on("change keyup input focusin", function() {
                        $(this)
                            .parent()
                            .parent()
                            .addClass("has-input");
                    });

                    $input.on("focusout", function() {
                        if ($(this).val() !== "") {
                            $(this)
                                .parent()
                                .parent()
                                .addClass("has-input");
                        } else {
                            $(this)
                                .parent()
                                .parent()
                                .removeClass("has-input");
                        }
                    });
                });
            }
        },
        page: {
            init: function() {
                $tertiaryNav = $('.header-tertiary-nav');

                if ($tertiaryNav.length) {
                    /**
                     *  Reveal Tertiary Menu when products_list_block is on the page
                     */

                    var someElement = document.querySelector(".interior-banner");

                    $(document).ready(function() {
                        window.onscroll = function() {
                            if (someElement.getBoundingClientRect().bottom <= 100) {
                                $("body").addClass("reveal-tertiary-nav");
                            } else {
                                $("body").removeClass("reveal-tertiary-nav");
                            }
                        };
                    });

                    /**
                     *  Activate menu item based on scroll position
                     */

                    var lastId,
                        topMenu = $(".header-tertiary-nav .product-section-nav"),
                        topMenuHeight = 55;
                    // All list items
                    (menuItems = topMenu.find("a")),
                        // Anchors corresponding to menu items
                        (scrollItems = menuItems.map(function() {
                            var item = $($(this).attr("href"));
                            if (item.length) {
                                return item;
                            }
                        }));

                    // Bind to scroll
                    $(window).scroll(function() {
                        // Get container scroll position
                        var fromTop = $(this).scrollTop() + 225;

                        // Get id of current scroll item
                        var cur = scrollItems.map(function() {
                            if ($(this).offset().top < fromTop) return this;
                        });
                        // Get the id of the current element
                        cur = cur[cur.length - 1];
                        var id = cur && cur.length ? cur[0].id : "";

                        if (lastId !== id) {
                            lastId = id;
                            lastIdElement = $("[href='#" + id + "']");
                            // Set/remove active class
                            menuItems
                                .parent()
                                .removeClass("active")
                                .end()
                                .filter(lastIdElement)
                                .parent()
                                .addClass("active");

                            $horizontal_offset =
                                lastIdElement.offset().left -
                                $(".product-section-nav-container").offset().left +
                                $(".product-section-nav-container").scrollLeft();

                            $(".product-section-nav-container").animate(
                                {
                                    scrollLeft: $horizontal_offset
                                },
                                "fast"
                            );
                        }
                    });
                }
            }
        },
        interior_banner_block: {
            init: function() {
                $('.interior-banner-block-description p').each(function( index ) {
                    $(this).addClass("animated fadeIn delay-" + index + "s");
                });
            }
        },
        page_template_home: {
            init: function() {

                $(".home-banner").slick({
                    infinite: false,
                    fade: true,
                    arrows: false,
                    dots: false
                });

                // banner navigation
                $(".nav-item[data-slide]:not(.go-to-link)").click(function(e) {
                    var slideno = $(this).data("slide");
                    $(".home-banner").slick("slickGoTo", slideno);

                    e.preventDefault();
                    href = '#top';

                    
                        $("html, body").animate(
                            {
                                scrollTop: $(href).offset().top - 200
                            },
                            500
                        );

                });
                $(".nav-item.go-to-link").click(function(e) {
                    e.preventDefault();
                    $link = $(this).data('link');
                    $target = $(this).data('target');

                    if ($target == '_blank') {
                        window.open($link, '_blank');
                    } else {
                        window.location = $link;
                    }
                });

                /**
                 * Crossfade the slide's background imagery
                 * adapted from:
                 * @link https://codepen.io/Archonius/pen/efEAD
                 */

                var duration    = 15,      // duration in seconds
                    fadeAmount  = 0.1;     // fade duration amount relative to the time the image is visible

                // shorthand for in- and out-fading
                function fadeInOut( element, fadeIn, visible, fadeOut, onComplete){
                  return $(element).animate( {opacity:1}, fadeIn ).delay( visible ).animate( {opacity:0}, fadeOut, onComplete);
                }

                // creates a animation loop
                function doAnimationLoop(element, fadeInTime, visibleTime, fadeOutTime, pauseTime){
                  fadeInOut(element,fadeInTime, visibleTime, fadeOutTime ,function(){
                    setTimeout(function(){
                      doAnimationLoop(element, fadeInTime, visibleTime, fadeOutTime, pauseTime);
                    },pauseTime);
                  });
                }

                //fade in out functionality
                $('.slick-slide').each(function() {
                    console.log('test');
                    $slideIndex = $(this).data('slick-index');

                    var images = $("#slide-" + $slideIndex + " .background-image-list div");
                    

                    var numImages = images.size();
                    var durationMs = duration * 1000;
                    var imageTime = 7000; // time the image is visible 
                    var fadeTime = imageTime * fadeAmount; // time for cross fading
                    var visibleTime = imageTime  - (imageTime * fadeAmount * 2);// time the image is visible with opacity == 1
                    var animDelay = visibleTime * (numImages - 1) + fadeTime * (numImages - 2); // animation delay/offset for a single image 

                    if (numImages > 1) {
                    images.each( function( index, element ){
                            if (index != 0) {
                              $(element).css("opacity","0");
                              setTimeout(function(){
                                doAnimationLoop(element,fadeTime, visibleTime, fadeTime, animDelay);
                              },visibleTime*index + fadeTime*(index-1));
                            } else {
                              setTimeout(function(){
                                $(element).animate({opacity:0},fadeTime, function(){
                                  setTimeout(function(){
                                    doAnimationLoop(element,fadeTime, visibleTime, fadeTime, animDelay);
                                  },animDelay )
                                });
                              },visibleTime);
                            }
                        });
                    }

                });



            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function(i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
